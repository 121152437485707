import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Link } from "gatsby";
import "../style/base.css";
import Grid from "@mui/material/Grid";

const Title = (props) => {
  return (
    <WrapBoxTypo fs="26px" fw="700" ls="5px">
      {props.title}
    </WrapBoxTypo>
  );
};

const Content = (props) => {
  return (
    <Box sx={{ mb: ["100px"] }}>
      <Box
        sx={{
          width: "100%",
          height: ["240px", "240px", "auto"],
          objectFit: "cover",
        }}
      >
        <GatsbyImage
          image={props.topImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box sx={{ maxWidth: "1100px", margin: "auto" }}>
        <Box sx={{ mt: ["30px", "40px"] }}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                sx={{ mb: ["60px", "60px", ""] }}
              >
                <Box textAlign="center">
                  <WrapBoxTypo
                    fw={["700"]}
                    fs={["18px", "20px"]}
                    color="#515D59"
                  >
                    {props.title}
                  </WrapBoxTypo>
                  <Box
                    whiteSpace="pre"
                    sx={{
                      display: ["none", "block"],
                      mt: ["30px", "40px"],
                      mb: ["60px", "70px"],
                    }}
                  >
                    <WrapBoxTypo fs={["14px", "16px"]} color="#747D7A">
                      {props.text}
                    </WrapBoxTypo>
                  </Box>
                  <Box
                    whiteSpace="pre"
                    color="#747D7A"
                    sx={{
                      display: ["block", "none"],
                      mt: ["30px", "40px"],
                      mb: ["60px", "70px"],
                    }}
                  >
                    <WrapBoxTypo fs={["14px", "16px"]} color="#747D7A">
                      {props.textSp}
                    </WrapBoxTypo>
                  </Box>
                  <Link to={props.link} style={{ textDecoration: "none" }}>
                    <WrapBoxTypo color="#515D59" fs={["12px", "14px"]}>
                      {props.linkText}
                    </WrapBoxTypo>
                    <Box display="flex" justifyContent="center">
                      <Box borderBottom="1px solid #515D59" width="20px" />
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Box
                sx={{
                  width: ["70%", "50%", "300px", "410px"],
                  margin: "auto",
                }}
              >
                <GatsbyImage
                  image={props.secondImage}
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const TopService = () => {
  const data = useStaticQuery(
    graphql`
      query {
        service1: file(relativePath: { eq: "topService/top-service-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        service1_1: file(
          relativePath: { eq: "topService/top-service-1-1.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        service2: file(relativePath: { eq: "topService/service-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        service2_1: file(
          relativePath: { eq: "topService/top-service-2-1.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        service3: file(relativePath: { eq: "topService/service-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        service3_1: file(
          relativePath: { eq: "topService/top-service-3-1.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const service1 = getImage(data.service1);
  const service1_1 = getImage(data.service1_1);
  const service2 = getImage(data.service2);
  const service2_1 = getImage(data.service2_1);
  const service3 = getImage(data.service3);
  const service3_1 = getImage(data.service3_1);

  const ContentArray = [
    {
      topImage: service1,
      secondImage: service1_1,
      title: "forugranola",
      text: `フォルグラノーラの一番の特徴は、サクッじゅわっの新食感。
  厳選した材料で、低温でじっくり丹念に焼き上げています。
  オンライン診断で、あなたにピッタリのグラノーラをご提案します。`,
      textSp: `フォルグラノーラの一番の特徴は、
  サクッじゅわっの新食感。
  厳選した材料で、低温でじっくり
  丹念に焼き上げています。
  オンライン診断で、あなたにピッタリの
  グラノーラをご提案します。`,
      linkText: "診断はこちらから",
      link: "/diagnosis",
    },
    {
      topImage: service2,
      secondImage: service2_1,
      title: "online shop",
      text: `オリジナルブレンドのコーヒー、自家製のレモンケーキをはじめ
  食にまつわるグッズを販売しています。
  あなたの日常に、心地よい満足感と刺激を与えられるような
  商品をお届けします。`,
      textSp: `オリジナルブレンドのコーヒー、
  自家製のレモンケーキをはじめ
  食にまつわるグッズを販売しています。
  あなたの日常に、
  心地よい満足感と刺激を与えられるような
  商品をお届けします。`,
      linkText: "オンラインショップはこちら",
      link: "/online_shop",
    },
    {
      topImage: service3,
      secondImage: service3_1,
      title: "foru catering",
      text: `季節の素材で丁寧に作ったお料理を
  みなさまの利用シーンに合わせてお届けします。
  お弁当、少人数から大人数のパーティー
  軽食からしっかりご飯まで幅広く対応します。
  `,
      textSp: `季節の素材で丁寧に作ったお料理を
  みなさまの利用シーンに合わせてお届けします。
  お弁当、少人数から大人数のパーティー
  軽食からしっかりご飯まで幅広く対応します。`,
      linkText: "ケータリングのプランを見る",
      link: "/catering",
    },
  ];

  return (
    <Box sx={{ mb: ["200px"] }}>
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "auto",
          mb: "60px",
          display: ["flex"],
          justifyContent: ["center"],
        }}
      >
        <Title title="Service" />
      </Box>
      {ContentArray.map((c) => {
        return (
          <Content
            topImage={c.topImage}
            secondImage={c.secondImage}
            title={c.title}
            text={c.text}
            linkText={c.linkText}
            link={c.link}
            textSp={c.textSp}
          />
        );
      })}
    </Box>
  );
};

export default TopService;
