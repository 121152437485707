import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Grid } from "@mui/material";
import { Link } from "gatsby";

const Title = (props) => {
  return (
    <WrapBoxTypo fs="26px" fw="700" ls="5px">
      {props.title}
    </WrapBoxTypo>
  );
};

const StoreContent = (props) => {
  return (
    <Box sx={{ mb: ["40px"] }}>
      <Grid
        container
        direction="row"
        alignItems="flex-end"
        sx={{ justifyContent: ["center", "space-between"] }}
      >
        <Grid item sx="6">
          <Box
            sx={{
              width: ["145px", "145px", "200px", "263px"],
              height: ["145px", "145px", "200px", "263px"],
            }}
          >
            <GatsbyImage
              image={props.image}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item sx="6" sx={{ width: "50%", pb: "10px", pl: ["15px", ""] }}>
          <Box minWidth="170px">
            <Box>
              {props.subText.length > 0 ? (
                <WrapBoxTypo
                  fs={["12px"]}
                  color={["#A7ADAB"]}
                  lh="17px"
                  ls="1px"
                >
                  {props.subText}
                </WrapBoxTypo>
              ) : (
                <></>
              )}
              <WrapBoxTypo
                fs={["16px", "18px"]}
                color={["#515D59"]}
                lh="26px"
                ls="1px"
                sx={{ pb: ["30px", "12px"] }}
              >
                {props.name}
              </WrapBoxTypo>
              <Link to={props.link} style={{ textDecoration: "none" }}>
                <WrapBoxTypo color={["#515D59"]} ls="1px" fs={["12px", "14px"]}>
                  店舗情報はこちら
                </WrapBoxTypo>
                <Box
                  sx={{ width: ["20px"], borderBottom: ["1px solid #515D59"] }}
                />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Store = () => {
  const data = useStaticQuery(
    graphql`
      query {
        head_office: file(relativePath: { eq: "store/store-head-office.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        harajuku: file(relativePath: { eq: "store/store-harajuku.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        office_shibuay: file(
          relativePath: { eq: "store/store-office-shibuya.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        office_tokyo: file(
          relativePath: { eq: "store/store-office-tokyo-world.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const head_office = getImage(data.head_office);
  const harajuku = getImage(data.harajuku);
  const office_shibuay = getImage(data.office_shibuay);
  const office_tokyo_world = getImage(data.office_tokyo);

  const ContentArray = [
    {
      name: "本店",
      subText: "",
      link: "/head-office",
      image: head_office,
    },
    {
      name: "原宿店",
      subText: "",
      link: "/harajuku",
      image: harajuku,
    },
    {
      name: "渋谷店",
      subText: "office",
      link: "/office-shibuya",
      image: office_shibuay,
    },
    {
      name: "東京ワールドゲート店",
      subText: "office",
      link: "/office-tokyo-world",
      image: office_tokyo_world,
    },
  ];

  return (
    <Box sx={{ mb: ["160px", "200px"], px: ["20px"] }}>
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "auto",
          display: ["flex"],
          justifyContent: ["center"],
          mb: ["60px"],
        }}
      >
        <Title title="Store" />
      </Box>
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "auto",
          display: ["flex", "block"],
          justifyContent: ["center", "normal"],
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {ContentArray.map((c) => {
            return (
              <Grid item xs={12} sm={6} md={6}>
                <StoreContent
                  name={c.name}
                  subText={c.subText}
                  link={c.link}
                  image={c.image}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Store;
