import * as React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const TopPageSecondView = () => {
  return (
    <Box sx={{ mb: ["160px", "160px", "200px"] }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
        >
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                whiteSpace: "pre",
                textAlign: ["center", "center", "right", "right"],
                py: ["42px", ""],
              }}
            >
              <WrapBoxTypo
                lh={["42px", "47px"]}
                fs={["14px", "16px"]}
                ls={["0px", "2px"]}
                color="#515D59"
              >
                {`forucafeで食べる食事やドリンク、
そこでのスタッフとの会話、
家で楽しむために通販で購入しているグラノーラなど。
一つ一つが日々の生活に、
心地良い満足感という刺激を与えてくれる。`}
              </WrapBoxTypo>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        >
          <StaticImage
            alt="img"
            src="../images/topPageSecondView/topPageSecondView-1.jpg"
            placeholder="blurred"
            quality="100"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          order={{ xs: 3, sm: 3, md: 3 }}
        >
          <StaticImage
            alt="img"
            src="../images/topPageSecondView/topPageSecondView-2.jpg"
            placeholder="blurred"
            quality="100"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          order={{ xs: 4, sm: 4, md: 4 }}
        >
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                whiteSpace: "pre",
                textAlign: ["center", "center", "left", "left"],
                py: ["42px", ""],
              }}
            >
              <WrapBoxTypo lh={["42px", "47px"]} color="#515D59">
                {`忙しい毎日に飲み込まれずに、
自分のペースで毎日を過ごす秘訣は、
forucafeにある。`}
              </WrapBoxTypo>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopPageSecondView;
