import * as React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
// import "aos/dist/aos.css";

const TextSection = (props) => {
  return (
    <Box
      bgcolor={props.bgcolor}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box padding="60px 0">
        <Box textAlign="center">
          <WrapBoxTypo color="#ffffff" fs="14p" lh={["31px", "49px"]}>
            forucafeの foru は
          </WrapBoxTypo>
        </Box>
        <Box textAlign="center">
          <WrapBoxTypo color="#ffffff" fs="29px" lh="49px" Marcellus={true}>
            for u = for you
          </WrapBoxTypo>
        </Box>
        <Box textAlign="center" sx={{ display: ["none", "block"] }}>
          <WrapBoxTypo color="#ffffff" fs="14px" lh="49px">
            つまり　”あなたのための”という想いを込めています。
          </WrapBoxTypo>
        </Box>
        <Box textAlign="center" sx={{ display: ["block", "none"] }}>
          <WrapBoxTypo color="#ffffff" fs="14px" lh="31px">
            つまり　”あなたのための”という
          </WrapBoxTypo>
          <WrapBoxTypo color="#ffffff" fs="14px" lh="31px">
            想いを込めています。
          </WrapBoxTypo>
        </Box>
      </Box>
    </Box>
  );
};

export default TextSection;
