import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Link } from "gatsby";
import { createClient } from "microcms-js-sdk";

const NewsContent = (props) => {
  return (
    <Box sx={{ display: ["block", "flex"], borderBottom: "1px solid #515D59" }}>
      <Box>
        <WrapBoxTypo sx={{ color: "#515D59" }} lh="42px">
          {props.date}
        </WrapBoxTypo>
      </Box>
      <Box>
        <WrapBoxTypo sx={{ color: "#515D59", ml: "60px" }} lh="42px">
          {props.title}
        </WrapBoxTypo>
      </Box>
    </Box>
  );
};

const NewsContentSp = (props) => {
  return (
    <Box sx={{ display: ["block", "flex"], borderBottom: "1px solid #515D59" }}>
      <Box>
        <WrapBoxTypo sx={{ color: "#515D59" }} lh="30px" fs="14px">
          {props.date}
        </WrapBoxTypo>
      </Box>
      <Box>
        <WrapBoxTypo sx={{ color: "#515D59", ml: "0" }} lh="30px" fs="14px">
          {props.title}
        </WrapBoxTypo>
      </Box>
    </Box>
  );
};

const NewsContentLink = (props) => {
  return (
    <Box mb="12px">
      {props.link ? (
        <Link
          to={props.link}
          style={{ textDecoration: "none", color: "#515D59" }}
        >
          <Box sx={{ display: ["none", "block"] }}>
            <NewsContent date={props.date} title={props.title} />
          </Box>
          <Box sx={{ display: ["block", "none"] }}>
            <NewsContentSp date={props.date} title={props.title} />
          </Box>
        </Link>
      ) : (
        <>
          <Box sx={{ display: ["none", "block"] }}>
            <NewsContent date={props.date} title={props.title} />
          </Box>
          <Box sx={{ display: ["block", "none"] }}>
            <NewsContentSp date={props.date} title={props.title} />
          </Box>
        </>
      )}
    </Box>
  );
};

const Title = (props) => {
  return (
    <WrapBoxTypo fs="26px" fw="700" ls="5px">
      {props.title}
    </WrapBoxTypo>
  );
};

const shopObject = {
  shibuya: "渋谷店",
  harajyuku: "原宿店",
  honten: "本店",
  world: "東京ワールドゲート店",
};

const News = (props) => {
  const [dlNews, setNews] = useState();
  const tempNewsArray = [];

  const client = createClient({
    serviceDomain: "forustyle",
    apiKey: process.env.API_KEY,
  });

  useEffect(() => {
    const text = props.shop
      ? `tag[contains]全店舗[or]tag[contains]${props.shop}${
          props.shop === "原宿店"
            ? "[or]tag[contains]本店＋原宿店"
            : props.shop === "本店"
            ? "[or]tag[contains]本店＋原宿店"
            : ""
        }`
      : "";
    client
      .get({
        endpoint: "news",
        queries: { filters: text },
        useGlobalDraftKey: false,
      })
      .then((res) => {
        res.contents.map((c) => {
          const tempObj = {
            id: c.id,
            date: c.date,
            title: c.title,
            link: c.link,
          };
          tempNewsArray.push(tempObj);
        });
        tempNewsArray.sort(function (a, b) {
          const regex = /\./gi;
          var newsA = a.date.replace(regex, "/");
          var newsB = b.date.replace(regex, "/");
          if (newsA < newsB) {
            return 1;
          }
          if (newsA > newsB) {
            return -1;
          }
          return 0;
        });

        setNews(tempNewsArray);
      });
  }, [setNews]);

  return (
    <Box sx={{ bgcolor: "#F3F5F5", p: "75px 20px 80px" }}>
      <Box
        sx={
          !props.isTop
            ? {
                maxWidth: "1100px",
                margin: "auto",
                display: ["flex", "block"],
                justifyContent: ["center", "normal"],
                mb: ["50px"],
              }
            : {
                maxWidth: "1100px",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                mb: ["50px"],
              }
        }
      >
        <Title title="News" />
      </Box>
      <Box
        sx={{
          maxWidth: "1100px",
          margin: ["auto"],
          overflowY: "scroll",
          height: ["335px", "170px"],
        }}
        overflow="hidden"
      >
        {dlNews?.map((c, i) => {
          return (
            <NewsContentLink link={c.link} date={c.date} title={c.title} />
          );
        })}
      </Box>
    </Box>
  );
};

export default News;
